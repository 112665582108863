import { useQueryParam, StringParam } from "use-query-params"

const getRegistrationCode = defaultRegistrationCode => {
  if (
    useQueryParam("code", StringParam) &&
    useQueryParam("code", StringParam)[0]
  ) {
    return useQueryParam("code", StringParam)[0]
  }
  return defaultRegistrationCode
}
export default getRegistrationCode
