import React from "react"
import PropTypes from "prop-types"
import { Box, Grid } from "@material-ui/core"
import sectionStyles from "src/styles/components/section.module.scss"

const defaultChildren = (
  <>
    <h1>Passende Workshops und Online-Seminare zu spannenden Themen</h1>
    <p>
      Du möchtest noch mehr über die Themen Stress, Achtsamkeit,
      Spitzenleistung, Schlaf, Entspannung und Mitgefühl erfahren?
      <br />
      Dann melde dich zu einem der Präsenzworkshops oder Online-Seminare an!
      <br />
      <b>Das geht ganz einfach direkt in der Mindance App.</b>
      <br />
      <br />
    </p>
  </>
)

const WorkshopsSection = ({ workshops, children }) => {
  return (
    <Box className={sectionStyles.colored}>
      <Box className="bounded-w">
        <Grid container component="section">
          <Grid item xs={12} md={6} lg={8}>
            {children}
            <Grid container spacing={2}>
              {workshops.map(workshop => (
                <Grid
                  key={workshop.name}
                  item
                  xs={6}
                  md
                  style={{ lineHeight: "1.5em" }}
                >
                  <div>{workshop.date}</div>
                  <div>{workshop.time}</div>
                  <div>
                    <b>{workshop.name}</b>
                  </div>
                  <div>{workshop.location}</div>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={0}
            sm={12}
            md={6}
            lg={4}
            className="screenshot-container cover-section-edge"
            alignItems="flex-end"
            justify="center"
            container
          >
            <img
              className="screenshot"
              src="/assets/img/marketing/workshops_reflection.png"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

WorkshopsSection.propTypes = {
  workshops: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.string,
    })
  ),
  children: PropTypes.node,
}

WorkshopsSection.defaultProps = {
  workshops: [],
  children: defaultChildren,
}

export default WorkshopsSection
